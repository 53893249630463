import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Col, Row } from 'react-bootstrap';
import { ProductItem, ProductItemImage, ProductRecommended } from './style';

const ProductRecommendation = ({ product, slug }) => {
      const [products, setProducts] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
            const loadData = async () => {
                  setIsLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/products/${slug}/recommended`,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_Api_Key
                                    }
                              }
                        )
                        .then((response) => {
                              setProducts(response.data.products);
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  setIsLoading(false);
            };

            loadData();
      }, []);

      return (
            <>
                  <ProductRecommended>
                        <div className="container p-0">
                              <div className="product__recommend--title my-3">
                                    <h2 className="fs-3">
                                          Recommended Product
                                    </h2>
                              </div>
                              <Row className="row mb-4 g-4">
                                    {!isLoading ? (
                                          products.length > 0 &&
                                          products.map((product, index) => (
                                                <Col
                                                      lg={3}
                                                      md={6}
                                                      sm={6}
                                                      key={index}
                                                      className="col-6"
                                                >
                                                      <ProductItem>
                                                            <div className="product__container">
                                                                  <ProductItemImage>
                                                                        <Link
                                                                              to={`/products/${product.slug}`}
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          product.image
                                                                                    }
                                                                                    alt={
                                                                                          product.title
                                                                                    }
                                                                                    className="img-fluid"
                                                                              />
                                                                        </Link>
                                                                  </ProductItemImage>
                                                                  <div className="product__content d-flex justify-content-between">
                                                                        <div className="product__info">
                                                                              <div className="product__title">
                                                                                    <Link
                                                                                          to={`/products/${product.slug}`}
                                                                                    >
                                                                                          <h4>
                                                                                                {
                                                                                                      product.title
                                                                                                }
                                                                                          </h4>
                                                                                    </Link>
                                                                              </div>
                                                                              <div className="product__price">
                                                                                    Rs:{' '}
                                                                                    {
                                                                                          product.price
                                                                                    }
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </ProductItem>
                                                </Col>
                                          ))
                                    ) : (
                                          <>Loading</>
                                    )}
                              </Row>
                        </div>
                  </ProductRecommended>
            </>
      );
};

export default ProductRecommendation;
