import styled from 'styled-components';

export const HeaderNavContainer = styled.div`
      @media screen and (max-width: 1224px) {
            display: none;
      }
`;

export const HeaderContainer = styled.div.attrs(() => ({
      className: 'container-fluid'
}))`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: 55px;
      padding-right: 55px;
      background-color: ${(props) => props.theme.white};

      @media screen and (max-width: 600px) {
            padding: 12px 20px;
      }
`;

export const HeaderLogo = styled.div`
      padding: 0;

      img {
            width: 100%;
            height: 80px;
            padding: 15px;
            object-fit: contain;
      }

      @media screen and (max-width: 600px) {
            img {
                  width: 140px;
                  padding: 0px;
                  height: auto;
            }
      }
`;

export const HeaderNav = styled.div`
      nav {
            display: flex;

            svg {
                  fill: ${(props) => props.theme.black};
                  height: 24px;
            }

            ul {
                  padding-left: 0;
                  list-style: none;
                  margin: 0;

                  li {
                        display: inline-block;

                        &:nth-child(4) {
                              margin-right: 16px;
                        }

                        i {
                              margin-left: -16px;
                        }

                        a {
                              text-decoration: none;
                              color: ${(props) => props.theme.black};
                              font-size: 16px;
                              padding: 20px 10px;
                              letter-spacing: 0.04rem;
                              position: relative;

                              &::after {
                                    transform: translateX(-50%);
                                    position: absolute;
                                    bottom: 14px;
                                    left: 50%;
                                    content: '';
                                    background: ${(props) => props.theme.black};
                                    width: 0%;
                                    height: 1px;
                              }
                        }

                        &:hover + a::after {
                              width: 65%;
                              transition: width 0.3s ease;
                        }
                  }
            }
      }
`;

export const MenuWrapper = styled.div`
      padding: 0px;

      &:hover {
            cursor: pointer;
      }
`;

export const MenuContainer = styled.div``;

export const MenuCustom = styled.div`
      display: inline-block;
      width: 72px;
      height: 14px;
      position: relative;
      text-decoration: none;

      span {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: ${(props) => props.theme.black};
            transition: all 0.5s ease;
      }
      .hover.top {
            width: 0px;
            top: 3px;
            bottom: auto;
            transform: translateX(-150%);
            opacity: 0;
            visibility: hidden;
      }

      .hover.bottom {
            width: 0;
            top: 10px;
            bottom: auto;
            transform: translateX(150%);
            opacity: 0;
            visibility: hidden;
      }

      .default.top {
            width: 100%;
            top: 3px;
            bottom: auto;
      }

      .default.bottom {
            width: 100%;
            top: 10px;
            height: 0.5px;
            bottom: auto;
      }

      &:hover .hover.top {
            width: 100%;
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
      }

      &:hover .hover.bottom {
            width: 100%;
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
      }

      &:hover .default.top {
            width: 0;
            transform: translateX(150%);
            opacity: 0;
            visibility: hidden;
      }

      &:hover .default.bottom {
            width: 0;
            transform: translateX(-150%);
            opacity: 0;
            visibility: hidden;
      }
`;
