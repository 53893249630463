import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
      HeaderContainer,
      HeaderLogo,
      HeaderNav,
      MenuContainer,
      MenuCustom,
      MenuWrapper
} from '../../styles/layouts/header';
import { HeartIcon, ShoppingCart, UserIcon } from '../../components/icons';
import CartContext from '../../stores/CartContext';
import WishlistContext from '../../stores/WishlistContext';

const OtherHeader = ({ handleShow, handleSearchShow }) => {
      const [isSticky, setIsSticky] = useState(false);
      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.pageYOffset;
                  setIsSticky(scrollTop > 200);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);

      const cartCtx = useContext(CartContext);
      const wishlistCtx = useContext(WishlistContext);

      return (
            <>
                  <header className={`${isSticky ? 'sticky' : ''}`}>
                        <HeaderContainer>
                              <HeaderLogo>
                                    <Link to="/">
                                          <img
                                                src="/images/png/logo.jpg"
                                                alt="Bathnroom Official Logo"
                                                className="img-fluid"
                                                height="100%"
                                                width="100%"
                                          />
                                    </Link>
                              </HeaderLogo>
                              <HeaderNav>
                                    <nav>
                                          <ul>
                                                <li>
                                                      <Link
                                                            to="#"
                                                            onClick={
                                                                  handleSearchShow
                                                            }
                                                            className="py-0"
                                                      >
                                                            <span
                                                                  className="material-symbols-outlined"
                                                                  style={{
                                                                        lineHeight: 2
                                                                  }}
                                                            >
                                                                  search
                                                            </span>
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/wishlists">
                                                            <HeartIcon />(
                                                            {
                                                                  wishlistCtx.totalWishlist
                                                            }
                                                            )
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/carts">
                                                            <ShoppingCart />(
                                                            {cartCtx.totalCart})
                                                      </Link>
                                                </li>
                                                <li style={{ height: '24px' }}>
                                                      <Link
                                                            to="/dashboard"
                                                            style={{
                                                                  height: '24px',
                                                                  width: '24px'
                                                            }}
                                                      >
                                                            <UserIcon />
                                                      </Link>
                                                </li>
                                                <li>
                                                      <MenuWrapper
                                                            onClick={handleShow}
                                                      >
                                                            <MenuContainer>
                                                                  <MenuCustom>
                                                                        <span className="hover top"></span>
                                                                        <span className="hover bottom"></span>
                                                                        <span className="default top"></span>
                                                                        <span className="default bottom"></span>
                                                                  </MenuCustom>
                                                            </MenuContainer>
                                                      </MenuWrapper>
                                                </li>
                                          </ul>
                                    </nav>
                              </HeaderNav>
                        </HeaderContainer>
                  </header>
            </>
      );
};

export default OtherHeader;
