import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import {
      ProductAdditional,
      ProductContent,
      ProductSlider
} from '../../components/frontend/product';
import ProductRecommendation from '../../components/frontend/product/ProductRecommendation';
import {
      ProductContainer,
      ProductContentDetail,
      ProductImageContent
} from '../../components/frontend/product/style';
import { Helmet } from 'react-helmet';

const ProductDetail = () => {
      const { productId } = useParams();

      const [loading, setLoading] = useState(false);
      const [product, setProduct] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setProduct(response.data.product);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [productId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{product.seo_title}</title>
                              <meta
                                    name="description"
                                    content={product.seo_description}
                              />
                              <meta
                                    name="keyword"
                                    content={product.seo_keyword}
                              />
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <div
                        className="product__details"
                        style={{ backgroundColor: '#f2f2f2' }}
                  >
                        <ProductContainer>
                              <Row>
                                    <Col lg={5}>
                                          <ProductImageContent>
                                                <ProductSlider
                                                      loading={loading}
                                                      product={product}
                                                />
                                          </ProductImageContent>
                                    </Col>
                                    <Col lg={7}>
                                          <ProductContentDetail>
                                                <ProductContent
                                                      product={product}
                                                />
                                          </ProductContentDetail>
                                    </Col>
                              </Row>
                              {!loading && (
                                    <>
                                          <ProductAdditional
                                                loading={loading}
                                                product={product}
                                                slug={productId}
                                          />
                                          <ProductRecommendation
                                                product={product}
                                                slug={productId}
                                          />
                                    </>
                              )}
                        </ProductContainer>
                  </div>
            </>
      );
};

export default ProductDetail;
